import sonyaAvatar from '../../assets/images/sonya.jpg';
import gemmaAvatar from '../../assets/images/gemma.jpg';
import aliceAvatar from '../../assets/images/alice.jpg';
import kjartanAvatar from '../../assets/images/kjartan.jpg';


export const testimonials = [
  {
    author: 'Kjartan Manvelyan',
    title: 'Managing Director',
    company: 'Manvelyan Consulting',
    image: kjartanAvatar,
    alt: 'Kjartan Manvelyan image',
    content: 'Petya is a delightful developer to work with. She is a dedicated problem solver who has the rare ability to keep focused on the task at hand, while being a very transparent communicator. Petya made sure our project was completed on time, and delivered high quality work, even under time pressure. I would be happy to recommend Petya to anyone, and look forward to working with her again.',
  },
  {
    author: 'Gemma Edward Aron',
    title: 'Executive Producer',
    company: 'MycoWorks Inc',
    image: gemmaAvatar,
    alt: 'Sonya Trivedi image',
    content: 'I had the chance to work with Petya on a pair of website projects. I found her to be very responsive, a fantastic problem solver, and a very clear communicator. I was pleasantly surprised that despite being separated by 8 hours I never found myself waiting for answers.  I look forward to working with Petya again in the future.',
  },
  {
    author: 'Alice Tatton Brown',
    title: 'Head of Digital Transformation',
    company: 'Le Wagon',
    image: aliceAvatar,
    alt: 'Alice Tatton Brown image',
    content: 'It is rare to find a developer who is equally talented across design & programming but Petya really is. She is great at understanding what the client needs & interpreting their vision both from a UX/UI perspective & also a practical point of view. I work with many corporates, consulting on their Digital Transformation programmes & for any technical training, I use Petya as my lead lecturer for workshops & webinars.',
  },
  {
    author: 'Sonya Trivedi',
    title: 'Founder and CEO',
    company: 'Adithana Marketing',
    image: sonyaAvatar,
    alt: 'Sonya Trivedi image',
    content: 'Petya worked with us on developing the website for our marketing agency Adithana Marketing. We managed to establish a great partnership together based on trust and professionalism. Petya delivered all tasks on time, and we managed to launch the website according to our plan with very satisfactory results. If you need a very qualified expert for your website development, we will highly recommend hiring Petya!',
  },
  // {
  //   author: 'Xavier Gallego',
  //   title: 'VP of brand and marketing',
  //   company: 'MycoWorks Inc',
  //   image: xeviAvatar,
  //   alt: 'Sonya Trivedi image',
  //   content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae dolores officia sunt fuga quasi adipisci vitae, totam placeat ab hic, sed, vel nihil asperiores dolor molestias incidunt reiciendis cupiditate veritatis.',
  // },
];