import React from "react";
import Project from "../../components/Project/Project";
import Contact from "../../components/Contact";
import Intro from "../../components/Intro/Intro";
import { projects } from "../../data/data";
import { revealTextWithLine } from "../../helpers/animations";
import TriangleShape from "../../components/TriangleShape/TriangleShape";
import Testimonials from "../../components/Testimonials";
import Skills from "../../components/Skills";
// import Footer from '../../components/Footer/Footer';

import "./Homepage.scss";

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.line = React.createRef();
    this.upper = React.createRef();
    this.state = {
      upperWrapHeight: 0,
    };
  }

  componentDidMount() {
    revealTextWithLine(this.line.current, this.upper.current);
    this.setState({
      upperWrapHeight: this.upper.current.offsetHeight,
    });
  }

  render() {
    const { mainColor, subColor } = this.props;

    return (
      <div className="container">
        <div className="grid">
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
        </div>
        <Intro mainColor={mainColor} subColor={subColor} />
        <section className="projects rellax">
          <div className="huge-text rellax">PROJECTS</div>
          <div
            className="section-title projects__title rellax text-right"
            data-rellax-speed="2"
          >
            <div
              className="upper-wrap"
              style={{ height: `${this.state.upperWrapHeight}px` }}
            >
              <h3 className="upper" ref={this.upper}>
                Projects
                <span className="dot" style={{ color: this.props.subColor }}>
                  .
                </span>
              </h3>
              <span className="line" ref={this.line}></span>
            </div>
          </div>
          <ul>
            {projects.map((project, index) => (
              <Project
                key={`project-${index}`}
                image={project.image}
                url={project.url}
                alt={project.alt}
                projects={projects}
                index={index}
                mainColor={mainColor}
                subColor={subColor}
              />
            ))}
          </ul>
          <TriangleShape
            width="20%"
            rellax="-1"
            right="72.2%"
            transform="rotate(45deg)"
            stroke={subColor}
            top="60%"
          />
          <TriangleShape
            width="15%"
            rellax="-1.5"
            right="16.8%"
            transform="rotate(0deg)"
            stroke={mainColor}
            top="70%"
          />
        </section>
        <Testimonials
          setTesimonialsRef={this.props.setTesimonialsRef}
          mainColor={mainColor}
          subColor={subColor}
        />
        <Skills mainColor={mainColor} subColor={subColor} />
        <Contact mainColor={mainColor} subColor={subColor} />
      </div>
    );
  }
}

export default Homepage;
