import React from "react";
import { ReactSVG } from "react-svg";
import pencilSvg from "../../assets/svg/edit.svg";
import writeSvg from "../../assets/svg/write.svg";
import heartSvg from "../../assets/svg/heart.svg";
import avatar1 from "../../assets/images/avatar1.jpg";

import "./Overlay.scss";
// import TriangleShape from '../TriangleShape/TriangleShape';

class Overlay extends React.Component {
  render() {
    const { subColor } = this.props;

    return (
      <React.Fragment>
        <div className="about">
          <div className="about__left">
            <h4>
              I'm a web developer, living and working in London. I have more
              than 6 years of experience in building{" "}
              <span className="accent-font">custom</span> websites.
              <br />I have worked with various clients in different business
              areas - corporate, fashion, hospitality, art, etc. My main goal is
              to create <span className="accent-font">engaging</span> and{" "}
              <span className="accent-font">user friendly</span> web
              experiences.
              <br />
              I'm also a Front End teacher at the #1 rated coding bootcamp -{" "}
              <a href="https://www.lewagon.com/">
                Le Wagon
                <span
                  className="underline"
                  style={{ backgroundColor: subColor }}
                ></span>
              </a>{" "}
              and a lead speaker for many of the workshops organised by them.
              <span>
                Drop me a line if you are interested in working with me!
              </span>
            </h4>
            <div className="about__contact">
              <div className="about__contact-icons">
                <ReactSVG src={pencilSvg} />
                <ReactSVG src={writeSvg} />
              </div>
              <a href="mailto:contact@petyanikolova.com">
                contact@petyanikolova.com
                <span
                  className="underline"
                  style={{ backgroundColor: subColor }}
                ></span>
              </a>
            </div>
            <div className="credits">
              Designed and built with
              <span>
                <ReactSVG className="heart-icon" src={heartSvg} />
              </span>
              by Petya Nikolova
            </div>
          </div>
          <div className="about__right">
            <div
              className="avatar"
              style={{ backgroundImage: `url(${avatar1})` }}
            >
              <span
                className="avatar__decoration"
                style={{ backgroundColor: subColor }}
              ></span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Overlay;
