import React from 'react';
import { revealTextWithLine } from '../../helpers/animations';
import { testimonials } from './data';
import Swiper from 'swiper';

import './Testimonials.scss';
import '../../assets/styles/components/swiper.scss';

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.line = React.createRef();
    this.upper = React.createRef();
    this.content = React.createRef();
    this.trigger = React.createRef();
    this.state = {
      upperWrapHeight: 0,
    }
  }

  componentDidMount() {
    revealTextWithLine(this.line.current, this.upper.current, 1.5);
    this.setState({
      upperWrapHeight: this.upper.current.offsetHeight,
    })

    var mySwiper = new Swiper('.swiper-container', { //eslint-disable-line
      loop: true,
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'bullets',
      //   clickable: true,
      // },
    })
  }

  render() {
    const { mainColor, subColor } = this.props;

    return (
      <section id="testimonials" ref={this.props.setTesimonialsRef} className="rellax text-right">
        <div className="section-title testimonials__title">
          <div className="upper-wrap" style={{ height: `${this.state.upperWrapHeight}px` }}>
            <h3 className="upper" ref={this.upper}>Testimonials<span className="dot" style={{ color: this.props.subColor }}>.</span></h3>
            <span className="line" ref={this.line}></span>
          </div>
        </div>
        {/*Slider main container */}
        <div id="testimonials__slider" className="swiper-container">
          {/* Additional required wrapper */}
          <div className="swiper-wrapper">
            {/* Slides */}
            {testimonials.map((testimonial, id) => {
              return (
                <div key={`testimonial-${id}`} className="swiper-slide testimonial">
                  <div className="testimonial__content">
                    <span className="quote" style={{ color: subColor }}>"</span>
                    <blockquote style={{ borderColor: mainColor }} ref={this.blockquote}>
                      <p className="testimonial__content__text slide-item-animate">
                        {testimonial.content}
                      </p>
                      <span className="testimonial__decoration__hide"></span>
                      <span className="testimonial__decoration__wave" style={{ borderColor: mainColor }}></span>
                    </blockquote>
                  </div>
                  <div className="testimonial__author">
                    <img className="testimonial__avatar slide-item-animate" src={testimonial.image} alt={testimonial.alt} ref={this.authorAvatar} />
                    <div className="testimonial__author__details">
                      <p className="testimonial__author__name slide-item-animate" ref={this.authorName}>- {testimonial.author}</p>
                      <p className="testimonial__author__title slide-item-animate" ref={this.authorTitle}>{testimonial.title}, {testimonial.company}</p>
                    </div>
                  </div>
                </div>
              )
            }
            )}
          </div>
          <div className="swiper-button-prev" style={{ backgroundColor: subColor }}></div>
          <div className="swiper-button-next" style={{ backgroundColor: subColor }}></div>
        </div>
        <div className="swiper-pagination"></div>
      </section>
    )
  }
}

export default Testimonials;