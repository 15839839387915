import React from 'react';

import './TriangleShape.scss';

const TriangleShape = (props) => {
  let stroke = '';
  let fill = '';

  switch (props.stroke) {
    case 'subColor':
      stroke = '#ffb961'; //yellow
      break;
    case 'mainColor':
      stroke = '#ffb5b5'; //pink
      break;
    default:
      stroke = props.stroke
      break;
  }

  switch (props.fill) {
    case 'subColor':
      fill = '#ffb961';
      break;
    case 'mainColor':
      fill = '#ffb5b5';
      break;
    default:
      fill = props.fill
      break;
  }

  return (
    <div
      className="triangle-shape rellax"
      id={props.id}
      data-rellax-speed={props.rellax}
      style={{
        width: props.width,
        height: props.width,
        top: props.top,
        right: props.right,
        bottom: props.bottom,
        left: props.left,
        transform: props.transform,
        stroke,
        fill,
        opacity: props.opacity,
      }}>
      <svg viewBox="0 0 100 100">
        <polygon id="e1_polygon" points="0 0, 0 100, 100 0" style={{ fill }} />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
  )
}


export default TriangleShape;