import { TimelineMax, TimelineLite, Power3, Power4 } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'animation.gsap';
import 'debug.addIndicators';

export const scaleImagesOnScroll = (image, imageContainer, imageCover) => {
  let controller = new ScrollMagic.Controller();
  let tl = new TimelineLite();

  tl
    .to(image, 1, { y: '-10%' }, 'scaleImage')
  // .to(imageContainer, 1, { scale: 1.05 }, 'scaleImage')
  // .to(imageCover, 1, { y: '-20%' }, 'scaleImage')
  let scene = new ScrollMagic.Scene({ // eslint-disable-line
    triggerElement: image,
    triggerHook: 0.5,
    duration: '100%',
  })
    .setTween(tl)
    .addTo(controller);
}

export const revealTextWithLine = (line, upper, triggerHook = 0.7) => {
  let controller = new ScrollMagic.Controller();
  const lineRef = line;
  const upperRef = upper;
  const elementHeight = upperRef.offsetHeight

  let tl = new TimelineMax();
  tl.from(lineRef, 0.8, { scaleX: 0, transformOrigin: "right center", ease: Power4.easeOut });
  tl.to(upperRef, 0.8, { y: `-${elementHeight}`, ease: Power4.easeOut });

  let scene = new ScrollMagic.Scene({ // eslint-disable-line
    triggerElement: upperRef,
    triggerHook,
    reverse: false,
  })
    .setTween(tl)
    // .addIndicators()
    .addTo(controller);
};

export const revealImages = (cover, image, cta = null) => {
  let controller = new ScrollMagic.Controller();
  const coverRef = cover;
  const imageRef = image;
  let tl = new TimelineMax();

  tl.from(coverRef, 0.5, { scaleY: 0, transformOrigin: 'left top', ease: Power3.easeOut });
  tl.to(coverRef, 0.7, { scaleY: 0, transformOrigin: 'left bottom', ease: Power3.easeIn }, 'reveal');
  tl.from(imageRef, 0.6, { opacity: 0 }, 'reveal');

  let scene = new ScrollMagic.Scene({ // eslint-disable-line 
    triggerElement: image,
    triggerHook: 0.7,
    reverse: false,
  })
    .setTween(tl)
    // .addIndicators()
    .addTo(controller);
}

export const drawBorder = (top, right, bottom, left, content, trigger = null, growthPercentage) => {
  let controller = new ScrollMagic.Controller();
  const tl = new TimelineLite();
  const speed = 0.3;

  tl
    .to(top, speed, {
      width: `${growthPercentage}%`
    })
    .to(right, speed, {
      height: `${growthPercentage}%`
    })
    .to(bottom, speed, {
      width: `${growthPercentage}%`
    })
    .to(left, speed, {
      height: `${growthPercentage}%`
    })
    .staggerFromTo(content, 0.5, {
      autoAlpha: 0,
      xPercent: -5
    }, {
      autoAlpha: 1,
      xPercent: 'auto'
    }, 0.2);

  let scene = new ScrollMagic.Scene({ // eslint-disable-line 
    triggerElement: trigger,
    triggerHook: 0.7,
    reverse: false,
  })
    .setTween(tl)
    // .addIndicators()
    .addTo(controller);
};

export const revealText = (content, trigger, triggerHook = 0.7) => {
  let controller = new ScrollMagic.Controller();
  const tl = new TimelineLite();

  tl.staggerFromTo(content, 0.5, {
    autoAlpha: 0,
    xPercent: -5
  }, {
    autoAlpha: 1,
    xPercent: 'auto'
  }, 0.2).delay(1);

  let scene = new ScrollMagic.Scene({ // eslint-disable-line 
    triggerElement: trigger,
    triggerHook,
    reverse: false,
  })
    .setTween(tl)
    .addTo(controller);
}
