import React from "react";
import { revealTextWithLine, drawBorder } from "../../helpers/animations";

import "./Skills.scss";

class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.line = React.createRef();
    this.upper = React.createRef();
    this.cover = React.createRef();
    this.topBorder = React.createRef();
    this.rightBorder = React.createRef();
    this.bottomBorder = React.createRef();
    this.leftBorder = React.createRef();
    this.content = React.createRef();
    this.details = React.createRef();
    this.state = {
      upperWrapHeight: 0,
    };
  }

  componentDidMount() {
    revealTextWithLine(this.line.current, this.upper.current);
    this.setState({
      upperWrapHeight: this.upper.current.offsetHeight,
    });

    drawBorder(
      this.topBorder.current,
      this.rightBorder.current,
      this.bottomBorder.current,
      this.leftBorder.current,
      this.content.current.children,
      this.details.current,
      100
    );
  }

  render() {
    const { mainColor } = this.props;

    return (
      <section className="skills rellax" data-rellax-speed="-1.5">
        <div className="huge-text" data-rellax-speed="-1">
          <span>DEV</span>
          <span>DESIGN</span>
        </div>
        <div className="section-title skills__title">
          <div
            className="upper-wrap"
            style={{ height: `${this.state.upperWrapHeight}px` }}
          >
            <h3 className="upper" ref={this.upper}>
              Skills
              <span className="dot" style={{ color: this.props.subColor }}>
                .
              </span>
            </h3>
            <span className="line" ref={this.line}></span>
          </div>
        </div>
        <div
          className="skills__details"
          ref={this.details}
          style={{ color: mainColor }}
        >
          <span
            className="border top-side"
            style={{ backgroundColor: mainColor }}
            ref={this.topBorder}
          ></span>
          <span
            className="border right-side"
            style={{ backgroundColor: mainColor }}
            ref={this.rightBorder}
          ></span>
          <span
            className="border bottom-side"
            style={{ backgroundColor: mainColor }}
            ref={this.bottomBorder}
          ></span>
          <span
            className="border left-side"
            style={{ backgroundColor: mainColor }}
            ref={this.leftBorder}
          ></span>
          <ul ref={this.content}>
            <li>Front End Development</li>
            <li>HTML, CSS, Javascript, React, Typescript</li>
            <li>Webflow Development</li>
            <li>Wordpress Development</li>
            <li>Web Design</li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Skills;
