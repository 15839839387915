import React from 'react';
import { TweenMax, Power4 } from 'gsap';
import SplitText from '../../assets/vendor/SplitText';
import { greeting } from './helpers';
// import TriangleShape from '../TriangleShape/TriangleShape';
import { drawBorder } from '../../helpers/animations';

import './Intro.scss';
// import TweenLite from 'gsap/TweenLite';

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.splittext1 = React.createRef();
    this.splittext2 = React.createRef();
    this.splittext3 = React.createRef();
    this.splittext4 = React.createRef();
    this.introShape = React.createRef();
    // this.splittext5 = React.createRef();
    // this.splittext6 = React.createRef();
    // this.splittext7 = React.createRef();
    // this.splittext8 = React.createRef();
    this.headerTextCopy = React.createRef();
    this.topBorder = React.createRef();
    this.rightBorder = React.createRef();
    this.bottomBorder = React.createRef();
    this.leftBorder = React.createRef();
    this.description = React.createRef();
  }

  componentDidMount() {
    const splitText = ref => {
      return new SplitText(ref, { type: 'chars' });
    }

    const animateSplitText = (ref) => {
      const splittext = splitText(ref);
      const chars = splittext.chars;
      var l = splittext.chars.length;

      this.animation = setTimeout(() => {
        for (let i = 0; i < l; i++) {
          TweenMax
            .from(chars[i], 1, {
              x: 50,
              opacity: 0,
              ease: Power4.easeOut,
              delay: 0.5 + (i * 0.025),
            })

          // TweenLite.to(this.headerTextCopy.current, 0.2, { autoAlpha: 1, delay: 0.4 })
        }
      }, 1500);
    }

    // const animateIntroShape = (ref) => {
    //   const introShape = ref;
    //   TweenLite.from(introShape, 1.3, {
    //     width: 0,
    //     height: 0,
    //     ease: Elastic.easeOut,
    //     delay: 2.2,
    //   })
    // }

    // animateIntroShape(this.introShape.current);
    animateSplitText(this.splittext1.current);
    animateSplitText(this.splittext2.current);
    animateSplitText(this.splittext3.current);
    animateSplitText(this.splittext4.current);

    // animateHeaderCopyText(this.splittext5.current)
    // animateHeaderCopyText(this.splittext6.current)
    // animateHeaderCopyText(this.splittext7.current)
    // animateHeaderCopyText(this.splittext8.current)

    this.animateBorder = setTimeout(() => {
      drawBorder(
        this.topBorder.current,
        this.rightBorder.current,
        this.bottomBorder.current,
        this.leftBorder.current,
        this.description.current,
        this.description.current,
        100
      )
    }, 2300)
  }

  componentWillUnmount() {
    clearTimeout(this.animation);
    clearTimeout(this.animateBorder);
  }

  render() {
    const { mainColor, subColor } = this.props;

    return (
      <section className="intro">
        <div className="intro__shape" ref={this.introShape} style={{ backgroundColor: `${mainColor}` }}>
          <h2 ref={this.headerTextCopy} className="intro__header--copy rellax" data-rellax-speed="-2" style={{ color: subColor }}>
            <span className="intro__greeting" ref={this.splittext5}>Good</span>
            <span className="intro__iam" ref={this.splittext6}> {`${greeting()},`}</span>
            <span className="intro__iam" ref={this.splittext7}>I am</span>
            <span className="intro__name" ref={this.splittext8}>Petya</span>
          </h2>
        </div>
        {/* <TriangleShape
          width="15%"
          rellax="-2"
          right="14.5%"
          top="40%"
          transform='rotate(45deg)'
          stroke={subColor} /> */}
        <div className="intro__header">
          <h2 className="rellax" data-rellax-speed="-2">
            <span className="intro__greeting" ref={this.splittext1}>Good</span>
            <span className="intro__iam" ref={this.splittext2}> {`${greeting()},`}</span>
            <span className="intro__iam" ref={this.splittext3}>I am</span>
            <span className="intro__name" ref={this.splittext4}>Petya</span>
          </h2>

        </div>
        <div className="description rellax" data-rellax-speed="0.5" ref={this.description}>
          <span className="description__decoration" style={{ borderColor: subColor }} ></span>
          <span className="border top-side" style={{ backgroundColor: mainColor }} ref={this.topBorder}></span>
          <span className="border right-side" style={{ backgroundColor: mainColor }} ref={this.rightBorder}></span>
          <span className="border bottom-side" style={{ backgroundColor: mainColor }} ref={this.bottomBorder}></span>
          <span className="border left-side" style={{ backgroundColor: mainColor }} ref={this.leftBorder}></span>
          <h1 style={{ color: mainColor }}><span>Web Developer, based in London.</span>
            <span> I like building beautiful websites</span>
            <span> and bringing creative designs to life!</span>
          </h1>
        </div>
      </section>
    );
  }
}

export default Intro;