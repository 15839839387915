import React from 'react';
import { revealTextWithLine, revealText } from '../../helpers/animations';

import './Contact.scss';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.line = React.createRef();
    this.upper = React.createRef();
    this.content = React.createRef();
    this.trigger = React.createRef();
    this.state = {
      upperWrapHeight: 0,
    }
  }

  componentDidMount() {
    revealTextWithLine(this.line.current, this.upper.current, 1.5);
    this.setState({
      upperWrapHeight: this.upper.current.offsetHeight,
    })
    revealText(this.content.current.children, this.upper.current, 1.5);
  }

  render() {
    return (
      <section className="contact rellax text-right" ref={this.trigger} data-rellax-speed="0">
        <div className="section-title contact__title">
          <div className="upper-wrap" style={{ height: `${this.state.upperWrapHeight}px` }}>
            <h3 className="upper" ref={this.upper}>Contact<span className="dot" style={{ color: this.props.subColor }}>.</span></h3>
            <span className="line" ref={this.line}></span>
          </div>
        </div>
        <div className="contact__details" ref={this.content}>
          <a href="mailto:contact@petyanikolova.com" style={{ color: this.props.mainColor }}>
            <span className="arrow" style={{ color: this.props.mainColor }}>⟶</span> contact@petyanikolova.com</a>
          <a href="https://github.com/petia182" style={{ color: this.props.mainColor }} target="_blank" rel="noopener noreferrer">
            <span className="arrow" style={{ color: this.props.mainColor }}>⟶</span> github.com/petia182</a>
        </div>
      </section>
    );
  }
}


export default Contact;