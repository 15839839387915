import React from 'react';
import { revealImages, scaleImagesOnScroll } from '../../helpers/animations';

import './Project.scss';

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.project = React.createRef();
    this.cover = React.createRef();
    this.image = React.createRef();
    // this.imageContainer = React.createRef();
    this.cta = React.createRef();
  }

  componentDidMount() {
    revealImages(this.cover.current, this.image.current);

    if (window.innerWidth > 600) {
      scaleImagesOnScroll(this.image.current, this.project.current);
    }
  }

  render() {
    const { url, image, index, mainColor, subColor } = this.props;
    return (
      <li ref={this.project} className="project-wrapper" data-rellax-speed="-1">
        <span className="corner-decoration" style={{ backgroundColor: mainColor }}></span>
        <span className="corner-decoration" style={{ backgroundColor: mainColor }}></span>
        <a href={url} target="_blank" rel="noopener noreferrer" className="project">
          {/* <figure className="project--image"> */}
          <figure ref={this.image} className="project--image" style={{ backgroundImage: `url(${image})` }}>
            {/* <img src={image} alt={alt} ref={this.image} /> */}
          </figure>
        </a>
        <div className="project--cta" ref={this.cta}>
          <span className="project--cta-text">view project<span className="arrow">⟶</span></span>
        </div>
        <div ref={this.cover} className="cover" style={{ backgroundColor: index % 2 === 0 ? mainColor : subColor }}></div>
      </li>
    )
  }
}

export default Project;