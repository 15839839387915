import React from "react";
import cx from "classnames";
import Rellax from "rellax";
import { TweenLite, Power4 } from "gsap/TweenLite";
import TimelineLite from "gsap/TimelineLite";
import CookieConsent from "react-cookie-consent";
import Homepage from "./pages/Homepage";
import Overlay from "./components/Overlay/Overlay";
import PageLoader from "./components/PageLoader";
import Footer from "./components/Footer/Footer";

import "./App.scss";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.openOverlay = this.openOverlay.bind(this);
    this.setRef = this.setRef.bind(this);
    this.overlay = React.createRef();
    this.siteNav = React.createRef();
    this.main = React.createRef();
    this.cursor = React.createRef();
    this.state = {
      isOverlayOpen: false,
      isFirefox: false,
      isIE: false,
      isMobile: false,
      mainColor: "orange",
      subColor: "darkviolet",
      blog: false,
      currentIndex: 0,
      nextIndex: 0,
    };

    this.testimonialsRef = null;
  }

  userAgent = () => navigator.userAgent.toLowerCase();

  setRef(element) {
    this.testimonialsRef = element;
  }

  activateColorChange = () => {
    const mainColorsArray = [
      "#e4c0c4",
      "aqua",
      "#5ab3ff",
      "cyan",
      "darkcyan",
      "darkkhaki",
      "#b926b9",
      "#85a948",
      "darkorchid",
      "#cc0033",
      "darksalmon",
      "darkviolet",
      "fuchsia",
      "orange",
      "violet",
      "#ff5349",
      "#e4c0c4",
      "gold",
    ];
    const subColorsArray = [
      "#f3d34a",
      "green",
      "#82005f",
      "khaki",
      "lightgreen",
      "lightpink",
      "#ffff56",
      "lime",
      "magenta",
      "navy",
      "olive",
      "orange",
      "pink",
      "red",
      "yellow",
      "lightblue",
      "#f3d34a",
      "purple",
    ];

    const randomColor = (colorArray) => {
      if (this.state.currentIndex > colorArray.length - 2) {
        this.setState({
          currentIndex: 0,
        });
      } else {
        this.setState({
          currentIndex: this.state.currentIndex + 1,
        });
      }
      return colorArray[this.state.currentIndex];
    };

    document.body.classList.add("color-change");
    this.setState({
      mainColor: randomColor(mainColorsArray),
      subColor: randomColor(subColorsArray),
    });
  };

  openOverlay() {
    this.setState({
      isOverlayOpen: !this.state.isOverlayOpen,
    });
    const playAnimation = new TimelineLite();
    const reverseAnimation = new TimelineLite();
    const overlay = this.overlay.current;
    const startValue = "polygon(100% 100%, 0.1% 100%, 0.1% 0.1%, 20% 0.1%)";
    const endValue = "polygon(100% 100%, 0.1% 100%, 0.1% 0.1%, 100% 0.1%)";
    const defaultValue =
      "polygon(0.0001% 100%, 0.0001% 100%, 0.0001% 0.0001%, 0.0001% 0.0001%)";
    document.body.classList.toggle("overlay--opened");

    if (!this.state.isOverlayOpen) {
      if (this.state.isFirefox || this.state.isIE) {
        playAnimation.to(overlay, 0.9, { opacity: 1, ease: Power4.easeOut });
      }
      playAnimation
        .to(overlay, 0.9, {
          webkitClipPath: startValue,
          ease: Power4.easeIn,
        })
        .to(
          overlay,
          0.8,
          {
            webkitClipPath: endValue,
            ease: Power4.easeOut,
          },
          "-=0.2"
        );
    } else {
      if (this.state.isFirefox || this.state.isIE) {
        reverseAnimation.to(overlay, 0.8, { opacity: 0, ease: Power4.easeIn });
      }
      reverseAnimation
        .fromTo(
          overlay,
          0.8,
          { webkitClipPath: endValue },
          { webkitClipPath: startValue, ease: Power4.easeIn }
        )
        .to(
          overlay,
          0.9,
          {
            webkitClipPath: defaultValue,
            ease: Power4.easeOut,
          },
          "-=0.2"
        );
    }
  }

  isMobile = () => {
    if (
      this.userAgent().match(/Android/i) ||
      this.userAgent().match(/webOS/i) ||
      this.userAgent().match(/iPhone/i) ||
      this.userAgent().match(/iPad/i) ||
      this.userAgent().match(/iPod/i) ||
      this.userAgent().match(/BlackBerry/i) ||
      this.userAgent().match(/Windows Phone/i)
    )
      this.setState({
        isMobile: true,
      });
  };

  componentDidMount() {
    // setting padding top to <main> to be equal to nav height
    this.main.current.style = `padding-top: ${this.siteNav.current.offsetHeight}px`;

    if (
      this.userAgent().indexOf("msie") > -1 ||
      this.userAgent().indexOf("trident/") > -1
    ) {
      this.setState({
        isIE: true,
      });
    }

    if (this.userAgent().indexOf("firefox") > -1) {
      // Do Firefox-related activities
      this.setState({
        isFirefox: true,
      });
    }

    if (window.outerWidth > 800) {
      var rellax = new Rellax(".rellax", {
        // eslint-disable-line
        center: true,
      });
    }

    /* Move custom cursor  */
    const moveCursor = () => {
      const cursor = this.cursor.current;

      document.addEventListener("mousemove", (event) => {
        TweenLite.to(cursor, 0.3, {
          left: `${event.pageX - 12.5}px`,
          top: `${event.pageY - window.scrollY - 12.5}px`,
        });
      });
    };
    if (!this.isMobile()) {
      moveCursor();
    }
  }

  render() {
    const { mainColor, subColor, isMobile, isFirefox, isIE, blog } = this.state;

    return (
      <div className="App">
        {!isMobile && (
          <div
            className="cursor"
            ref={this.cursor}
            style={{ borderColor: mainColor }}
          ></div>
        )}
        <a
          href="https://onepagelove.com/petya-nikolova"
          target="_blank"
          rel="noopener noreferrer"
          className="one-page-award"
        >
          <img
            width="73"
            height="56"
            src="https://s3.amazonaws.com/onepagelove/one-page-love-award-retina-left.png"
            srcSet="https://s3.amazonaws.com/onepagelove/one-page-love-award-left.png 1x , https://s3.amazonaws.com/onepagelove/one-page-love-award-retina-left.png 2x"
            alt="One Page Love Award"
          />
        </a>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          style={{ fontSize: "11px", zIndex: 50 }}
          buttonStyle={{ background: mainColor }}
        >
          This website uses cookies.
        </CookieConsent>
        <PageLoader mainColor={mainColor} />
        <div
          className={cx("overlay", {
            "overlay--firefox": isFirefox,
            "overlay--ie": isIE,
            "overlay--not-firefox": !isFirefox,
            "overlay--not-ie": !isIE,
          })}
          style={{
            background: `radial-gradient(circle 42em, #fff, ${mainColor})`,
          }}
          ref={this.overlay}
        >
          <div className="container">
            <button className="close-overlay-button" onClick={this.openOverlay}>
              ╳
            </button>
            <Overlay subColor={subColor}></Overlay>
          </div>
        </div>
        <nav className="site-nav" ref={this.siteNav}>
          <ul>
            <li className="nav-link">
              <button onClick={this.openOverlay}>About me</button>
              <span
                className="underline"
                style={{ backgroundColor: mainColor }}
              ></span>
            </li>
            <li className="nav-link">
              <a href="#testimonials">Testimonials</a>
              <span
                className="underline"
                style={{ backgroundColor: mainColor }}
              ></span>
            </li>
            {blog && (
              <li className="nav-link">
                <a href="/blog">Blog</a>
                <span
                  className="underline"
                  style={{ backgroundColor: mainColor }}
                ></span>
              </li>
            )}
          </ul>
        </nav>
        <main ref={this.main}>
          <div className="wrapper">
            <div className="activate-color-change">
              <button
                onClick={this.activateColorChange}
                style={{ backgroundColor: subColor, borderColor: mainColor }}
              >
                <span>MIX IT UP!</span>
                <span
                  className="activate-color-change__decoration"
                  style={{ backgroundColor: mainColor }}
                ></span>
              </button>
            </div>
            <Homepage
              mainColor={mainColor}
              subColor={subColor}
              setTesimonialsRef={this.setRef}
            ></Homepage>
          </div>
          <Footer mainColor={mainColor} subColor={subColor} />
        </main>
      </div>
    );
  }
}

export default App;
