import imgLinden from "../assets/images/lindenstaub.png";
// import imgOptomen from "../assets/images/optomen.png";
import imgPreto from "../assets/images/preto.png";
import imgSavian from "../assets/images/savian.png";
import imgAdithana from "../assets/images/adithana.png";
// import imgReishi from '../assets/images/reishi.png';
import imgMycoworks from "../assets/images/mycoworks.png";

export const projects = [
  {
    name: "Savian",
    image: imgSavian,
    parallaxSpeed: 8,
    url: "https://www.savian.bio",
    alt: "Savian Project",
  },
  {
    name: "Mycoworks",
    image: imgMycoworks,
    parallaxSpeed: 7,
    url: "https://www.mycoworks.com",
    alt: "Mycoworks Project",
  },
  {
    name: "Preto",
    image: imgPreto,
    parallaxSpeed: 7,
    url: "https://www.preto.co.uk",
    alt: "Preto Project",
  },
  {
    name: "Linden Staub",
    image: imgLinden,
    parallaxSpeed: 8,
    url: "https://lindenstaub.com",
    alt: "Linden Staub Project",
  },
  {
    name: "Adithana Marketing",
    image: imgAdithana,
    parallaxSpeed: 7,
    url: "https://adithanamarketing.com",
    alt: "Adithana Marketing Project",
  },
];
