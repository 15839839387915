import React from "react";
import DrawSvgPlugin from "../../assets/vendor/DrawSVGPlugin"; //eslint-disable-line

import "./PageLoader.scss";
import { TimelineLite } from "gsap/TimelineLite";
import { Power1, Power3, Power4 } from "gsap/TweenLite";
// import { Power3 } from 'gsap/TweenLite';

class PageLoader extends React.Component {
  constructor(props) {
    super(props);
    // this.openOverlay = this.openOverlay.bind(this);
    this.overlay = React.createRef();
    this.logo = React.createRef();
    this.logoPath = React.createRef();
  }

  componentDidMount() {
    const tl = new TimelineLite();
    const overlay = this.overlay.current;
    const logo = this.logo.current;
    const logoPath = this.logoPath.current;

    tl.fromTo(
      logoPath,
      1.5,
      { drawSVG: "0%" },
      { drawSVG: "100%", ease: Power3.easeIn }
    )
      .fromTo(
        logo,
        0.3,
        { fill: "transparent" },
        { fill: "white", ease: Power1.easeIn },
        "-=0.6"
      )
      .to(logo, 0.4, { x: "-30px", autoAlpha: 0, ease: Power4.easeIn })
      .to(overlay, 0.6, {
        scaleX: 0,
        transformOrigin: "left",
        ease: Power3.easeOut,
      })
      .to(overlay, 0.6, {
        scaleX: 0,
        transformOrigin: "right",
        ease: Power3.easeIn,
      });
  }

  render() {
    const { mainColor } = this.props;

    return (
      <aside
        className="page-loader"
        ref={this.overlay}
        style={{ backgroundColor: mainColor }}
      >
        <div className="logo-loader">
          <svg
            ref={this.logo}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 50"
            version="1.1"
            width="280"
            height="300"
            fill="transparent"
          >
            <g id="surface1">
              <path
                ref={this.logoPath}
                stroke="white"
                d="M 18 0 C 15.253906 0 13 2.253906 13 5 L 13 32.21875 L 10.40625 29.6875 C 8.121094 27.402344 4.398438 27.558594 2.25 30.0625 C 2.238281 30.0625 2.230469 30.0625 2.21875 30.0625 C 0.351563 32.304688 0.722656 35.632813 2.65625 37.6875 C 2.667969 37.699219 2.675781 37.707031 2.6875 37.71875 L 8.90625 43.90625 C 12.886719 47.886719 18.125 50.09375 23.6875 50.09375 L 34 50.09375 C 41.144531 50.09375 47 44.238281 47 37.09375 L 47 7 C 47 4.253906 44.746094 2 42 2 C 39.253906 2 37 4.253906 37 7 L 37 15.03125 C 36.160156 14.390625 35.128906 14 34 14 C 32.371094 14 30.914063 14.8125 30 16.03125 C 29.085938 14.8125 27.628906 14 26 14 C 24.871094 14 23.839844 14.390625 23 15.03125 L 23 5 C 23 2.253906 20.746094 0 18 0 Z M 18 2 C 19.65625 2 21 3.34375 21 5 L 21 27 C 21 29.746094 23.253906 32 26 32 C 27.628906 32 29.085938 31.1875 30 29.96875 C 30.914063 31.1875 32.371094 32 34 32 C 36.746094 32 39 29.746094 39 27 L 39 7 C 39 5.34375 40.34375 4 42 4 C 43.65625 4 45 5.34375 45 7 L 45 37.09375 C 45 43.148438 40.054688 48.09375 34 48.09375 L 23.6875 48.09375 C 18.652344 48.09375 13.933594 46.121094 10.3125 42.5 L 4.125 36.3125 C 4.117188 36.300781 4.101563 36.292969 4.09375 36.28125 C 2.851563 34.929688 2.660156 32.691406 3.78125 31.34375 C 5.234375 29.667969 7.492188 29.585938 9 31.09375 C 9 31.105469 9 31.113281 9 31.125 L 13.3125 35.3125 C 13.601563 35.585938 14.027344 35.664063 14.390625 35.507813 C 14.757813 35.351563 14.996094 34.992188 15 34.59375 L 15 5 C 15 3.34375 16.34375 2 18 2 Z M 26 16 C 27.65625 16 29 17.34375 29 19 L 29 27 C 29 28.65625 27.65625 30 26 30 C 24.34375 30 23 28.65625 23 27 L 23 19 C 23 17.34375 24.34375 16 26 16 Z M 34 16 C 35.65625 16 37 17.34375 37 19 L 37 27 C 37 28.65625 35.65625 30 34 30 C 32.34375 30 31 28.65625 31 27 L 31 19 C 31 17.34375 32.34375 16 34 16 Z "
              />
            </g>
          </svg>
        </div>
      </aside>
    );
  }
}

export default PageLoader;

document.addEventListener("DOMContentLoaded", function () {
  // Side nav animation

  const menuToggle = document.querySelector(".menu-toggle");
  // const siteHeader = document.querySelector(".navbar");
  const sideNav = document.querySelector(".side-nav");
  const body = document.body;

  menuToggle &&
    menuToggle.addEventListener("click", function (event) {
      console.log("menu toggle click");

      if (body.classList.contains("menu-open")) {
        menuToggle.classList.remove("is-active");
        sideNav.classList.remove("is-active");
        body.classList.remove("menu-open");
      } else {
        menuToggle.classList.add("is-active");
        sideNav.classList.add("is-active");
        body.classList.add("menu-open");
      }
    });

  // Navbar background scroll animation

  // const navLink = document.querySelector(".nav-link");
  // const getInitialNavLinkColor =
  //   navLink &&
  //   window.getComputedStyle(document.querySelector(".nav-link")).color;
  // const scrollHeight = 100;
  // const beige = "#f5f2e6";

  // const updateColor = (item, styleProperty) => {
  //   if (window.scrollY > scrollHeight) {
  //     item.style[styleProperty] = beige; // Change to the desired color on scroll
  //   } else {
  //     item.style[styleProperty] = getInitialNavLinkColor;
  //   }
  // };

  // window.addEventListener("scroll", function () {
  //   const navbar = document.getElementById("navbar");
  //   const menuItems = document.querySelectorAll("#navbar .nav-link");
  //   const logoSvg = document.querySelector("#navbar .savian-logo-svg g");
  //   const menuLines = document.querySelectorAll("#navbar .menu-bar-line");

  //   // Change background gradient on scroll
  //   if (window.scrollY > scrollHeight) {
  //     navbar.classList.add("scrolling");
  //     navbar.style.background =
  //       "linear-gradient(to bottom, #1d1d1c, rgb(255,255,255,0))";
  //     logoSvg.style.fill = beige;
  //   } else {
  //     navbar.classList.remove("scrolling");
  //     navbar.style.background = "transparent";
  //     logoSvg.style.fill = getInitialNavLinkColor;
  //   }

  //   // Change color of menu items on scroll
  //   menuItems.forEach(function (item) {
  //     updateColor(item, "color");
  //   });

  //   menuLines.forEach(function (item) {
  //     updateColor(item, "background");
  //   });
  // });
});
